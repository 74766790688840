import React from "react";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function DropDownMenu({ anchorEl, open, onClose, data }) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(open)}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      PaperProps={{
        style: {
          background: "linear-gradient(180deg, #000000 0%, #283266 100%)",
          border: "none",
          borderRadius: "20px",
          padding: "1em",
        },
      }}
    >
      {data.map((item, index) => (
        <MenuItem
          key={index}
          sx={{ mt: "1em" }}
          component={Link}
          to={item?.link}
          onClick={() => onClose()}
        >
          <Box sx={{ display: "flex", gap: "1em", alignItems: "top" }}>
            <img
              src={item.image}
              alt={item.title}
              style={{ width: "30px", height: "30px" }}
            />

            <Box sx={{ "&>*": { color: "#FFF" } }}>
              <Typography variant="h6">{item.title}</Typography>
              <Typography variant="body2">{item.description}</Typography>
            </Box>
          </Box>
        </MenuItem>
      ))}
    </Menu>
  );
}

export default DropDownMenu;
