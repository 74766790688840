import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { logo1 } from "../assets";
import {
  Instagram,
  LinkedIn,
  X,
  Language,
  Copyright,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Box sx={{ bgcolor: "#000" }}>
      <Grid container spacing={2} sx={{ p: "2em" }}>
        <Grid item xs={12} md={6} lg={3}>
          <img src={logo1} alt="" />

          {/* <Typography variant="subtitle2" sx={{ color: "#8E8E93", mt: "2em" }}>
            Follow Us On
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "0.5em",
              cursor: "pointer",
              "&>*": {
                "&:hover": {
                  transition: "0.3s all",
                  transform: "scale(1.1)",
                },
              },
            }}
          >
            <IconButton
              sx={{
                "&:hover": { "&>*": { color: "primary.main" } },
                "&>*": { color: "#FFF", transition: "0.3s all" },
              }}
              onClick={() => window.open("", "_blank")}
            >
              <Instagram />
            </IconButton>
            <IconButton
              sx={{
                "&:hover": { "&>*": { color: "primary.main" } },
                "&>*": { color: "#FFF", transition: "0.3s all" },
              }}
            >
              <LinkedIn />
            </IconButton>
            <IconButton
              sx={{
                "&:hover": { "&>*": { color: "primary.main" } },
                "&>*": { color: "#FFF", transition: "0.3s all" },
              }}
            >
              <X />
            </IconButton>
            <IconButton
              sx={{
                "&:hover": { "&>*": { color: "primary.main" } },
                "&>*": { color: "#FFF", transition: "0.3s all" },
              }}
            >
              <Language />
            </IconButton>
          </Box> */}
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={3}
          sx={{
            "&>a": {
              color: "#8E8E93",
              "&:hover": { color: "primary.main" },
              transition: "0.3s all",
              p: 0,
              textAlign: "start",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "0.5em",
          }}
        >
          <Typography sx={{ color: "#FFF", mb: "2em" }}>Business</Typography>

          <Button variant="text" component={Link} to={"/streamlines"}>
            Streamlines
          </Button>
          <Button variant="text" component={Link} to={"/resources/culture"}>
            Culture
          </Button>
          <Button variant="text" component={Link} to={"/resources/about-us"}>
            About Us
          </Button>
          <Button variant="text" component={Link} to={"/resources"}>
            Careers
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={3}
          sx={{
            "& Button": {
              color: "#8E8E93",
              "&:hover": { color: "primary.main" },
              transition: "0.3s all",
              p: 0,
              textAlign: "start",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "0.5em",
          }}
        >
          <Typography sx={{ color: "#FFF", mb: "2em" }}>Info</Typography>

          <Button variant="text">Privacy Policy</Button>
          <Button variant="text">Terms and Conditions</Button>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={3}
          sx={{
            "& Button": {
              color: "#8E8E93",
              "&:hover": { color: "primary.main" },
              transition: "0.3s all",
              p: 0,
              textAlign: "start",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "0.5em",
          }}
        >
          <Typography sx={{ color: "#FFF", mb: "2em" }}>Contact</Typography>

          <Button
            variant="text"
            onClick={() => {
              const googleMapsUrl =
                "https://www.google.com/maps/place/Shotec+S.A.E./@30.0782981,31.324515,17z/data=!3m1!4b1!4m6!3m5!1s0x14583e3e961707b5:0xe66961bc818c3417!8m2!3d30.0782935!4d31.3270899!16s%2Fg%2F1tfv7l_c?entry=ttu&g_ep=EgoyMDI0MTAxNi4wIKXMDSoASAFQAw%3D%3D";

              window.open(googleMapsUrl, "_blank");
            }}
          >
            1 Marwa Buildings, Ahmed Tayseer St., Heliopolis, Cairo, Egypt
          </Button>
          <Button
            variant="text"
            onClick={() => {
              const mailToLink = `mailto:info@neo-dg.com`;
              const mailSubject = encodeURIComponent("Website @Neo");

              window.location.href = `${mailToLink}?subject=${mailSubject}`;
            }}
          >
            Info@neo-dg.com
          </Button>
          <Button variant="text">(+20) 6900998</Button>
        </Grid>
      </Grid>

      <Divider component="li" sx={{ borderColor: "#FFF", m: "1em 0" }} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5em",
          p: "0 1em 1em 1em",
        }}
      >
        <Copyright sx={{ color: "#FFF" }} />

        <Typography variant="body2" sx={{ color: "#FFF" }}>
          Copyright | All Rights Reserved
        </Typography>
      </Box>
    </Box>
  );
}
