import { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PageLoader from "../Components/PageLoader";

const Home = lazy(() => import("../Pages/Home/index"));
const Streamlines = lazy(() => import("../Pages/Streamlines/index"));
const NeoDigital = lazy(() => import("../Pages/Streamlines/Digital/index"));
const NeoPay = lazy(() => import("../Pages/Streamlines/Pay/index"));
const NeoBusiness = lazy(() => import("../Pages/Streamlines/Business/index"));
const NeoGaming = lazy(() => import("../Pages/Streamlines/Gaming/index"));
const NeoLearning = lazy(() => import("../Pages/Streamlines/Learning/index"));
const Resources = lazy(() => import("../Pages/Resources/index"));
const Contact = lazy(() => import("../Pages/Contact/index"));
const AboutUs = lazy(() => import("../Pages/Resources/AboutUs/index"));
const Newsletter = lazy(() => import("../Pages/Resources/Newsletter/index"));
const Partner = lazy(() => import("../Pages/Resources/Partner/index"));
const Culture = lazy(() => import("../Pages/Resources/Culture/index"));

export const Router = () => {
  const location = useLocation();

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop(window.location.href);
  }, [location]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<PageLoader />}>
            <Home />
          </Suspense>
        }
      />

      <Route
        path="/streamlines"
        element={
          <Suspense fallback={<PageLoader />}>
            <Streamlines />
          </Suspense>
        }
      />

      <Route
        path="/streamlines/neo-digital"
        element={
          <Suspense fallback={<PageLoader />}>
            <NeoDigital />
          </Suspense>
        }
      />

      <Route
        path="/streamlines/neo-pay"
        element={
          <Suspense fallback={<PageLoader />}>
            <NeoPay />
          </Suspense>
        }
      />

      <Route
        path="/streamlines/neo-business"
        element={
          <Suspense fallback={<PageLoader />}>
            <NeoBusiness />
          </Suspense>
        }
      />

      <Route
        path="/streamlines/neo-gaming"
        element={
          <Suspense fallback={<PageLoader />}>
            <NeoGaming />
          </Suspense>
        }
      />

      <Route
        path="/streamlines/neo-learning"
        element={
          <Suspense fallback={<PageLoader />}>
            <NeoLearning />
          </Suspense>
        }
      />

      <Route
        path="/resources"
        element={
          <Suspense fallback={<PageLoader />}>
            <Resources />
          </Suspense>
        }
      />

      <Route
        path="/resources/about-us"
        element={
          <Suspense fallback={<PageLoader />}>
            <AboutUs />
          </Suspense>
        }
      />

      <Route
        path="/resources/newsletter"
        element={
          <Suspense fallback={<PageLoader />}>
            <Newsletter />
          </Suspense>
        }
      />

      <Route
        path="/resources/partner"
        element={
          <Suspense fallback={<PageLoader />}>
            <Partner />
          </Suspense>
        }
      />

      <Route
        path="/resources/culture"
        element={
          <Suspense fallback={<PageLoader />}>
            <Culture />
          </Suspense>
        }
      />

      <Route
        path="/contact"
        element={
          <Suspense fallback={<PageLoader />}>
            <Contact />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
