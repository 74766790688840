import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import {
  aboutVector,
  businessVector,
  careersVector,
  cultureVector,
  digitalVector,
  gamingVector,
  learningVector,
  logo1,
  newsletterVector,
  partnerVector,
  payVector,
} from "../assets";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { PhoneOutlined } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import DropDownMenu from "./../Components/DropDownMenu/index";
import CircleIcon from "@mui/icons-material/Circle";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const streamlinesData = [
  {
    title: "NEO Digital",
    description: "Lorem ipsum dolor sit amet consectetur elit",
    image: digitalVector,
    link: "/streamlines/neo-digital",
  },
  {
    title: "NEO Pay",
    description: "Lorem ipsum dolor sit amet consectetur elit",
    image: payVector,
    link: "/streamlines/neo-pay",
  },
  {
    title: "NEO Business",
    description: "Lorem ipsum dolor sit amet consectetur elit",
    image: businessVector,
    link: "/streamlines/neo-business",
  },
  {
    title: "NEO Gaming & Entertainment",
    description: "Lorem ipsum dolor sit amet consectetur elit",
    image: gamingVector,
    link: "/streamlines/neo-gaming",
  },
  {
    title: "NEO E-Learning",
    description: "Lorem ipsum dolor sit amet consectetur elit",
    image: learningVector,
    link: "/streamlines/neo-learning",
  },
];

const resourcesData = [
  {
    title: "About Us",
    description: "Lorem ipsum dolor sit amet consectetur elit",
    image: aboutVector,
    link: "/resources/about-us",
  },

  {
    title: "Careers",
    description: "Lorem ipsum dolor sit amet consectetur elit",
    image: careersVector,
    link: "/resources",
  },

  {
    title: "Become a Partner",
    description: "Lorem ipsum dolor sit amet consectetur elit",
    image: partnerVector,
    link: "/resources/partner",
  },

  // {
  //   title: "Newsletter",
  //   description: "Lorem ipsum dolor sit amet consectetur elit",
  //   image: newsletterVector,
  //   link: "/resources/newsletter",
  // },

  {
    title: "Our Culture",
    description: "Lorem ipsum dolor sit amet consectetur elit",
    image: cultureVector,
    link: "/resources/culture",
  },
];

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [dropDownMenuOpen, setDropDownMenuOpen] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [anchorElm, setAnchorElm] = useState(null);

  const handleMenu = (data) => (event) => {
    setSelectedData(data);
    setAnchorEl(event.currentTarget);
    setDropDownMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setSelectedData(null);
    setAnchorEl(null);
    setDropDownMenuOpen(false);
  };

  const open = Boolean(anchorElm);

  const handleMenuClick = (event) => {
    setAnchorElm(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElm(null);
  };

  return (
    <AppBar position="static" sx={{ bgcolor: "#000", border: "none" }}>
      <Toolbar
        disableGutters
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1em",
          p: "0.5em 1em",
          border: "none",
        }}
      >
        <img
          src={logo1}
          alt=""
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        />

        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: "1em",
            "&>Button": {
              "&:hover": {
                color: "primary.main",
              },
            },
          }}
        >
          <Button
            variant="text"
            onClick={() => navigate("/")}
            sx={{ color: location.pathname === "/" ? "primary.main" : "#FFF" }}
          >
            <CircleIcon
              sx={{
                color: location.pathname === "/" ? "primary.main" : "#FFF",
                width: "5px",
              }}
            />
            Home
          </Button>
          <Button
            variant="text"
            onClick={handleMenu(streamlinesData)}
            sx={{
              color: location.pathname.includes("/streamlines")
                ? "primary.main"
                : "#FFF",
            }}
          >
            <CircleIcon
              sx={{
                color: location.pathname.includes("/streamlines")
                  ? "primary.main"
                  : "#FFF",
                width: "5px",
              }}
            />
            Streamlines
          </Button>
          <Button
            variant="text"
            onClick={handleMenu(resourcesData)}
            sx={{
              color: location.pathname.includes("/resources")
                ? "primary.main"
                : "#FFF",
            }}
          >
            <CircleIcon
              sx={{
                color: location.pathname.includes("/resources")
                  ? "primary.main"
                  : "#FFF",
                width: "5px",
              }}
            />
            Resources
          </Button>

          <DropDownMenu
            anchorEl={dropDownMenuOpen}
            open={Boolean(dropDownMenuOpen)}
            onClose={handleCloseMenu}
            data={selectedData ?? streamlinesData}
          />
        </Box>

        <Box sx={{ flexGrow: 0, display: { xs: "none", md: "block" } }}>
          <Button
            variant="contained"
            component={Link}
            to={"/contact"}
            sx={{
              gap: "0.5em",
              borderRadius: "10px",
            }}
          >
            <PhoneOutlined />
            <Typography>Contact Sales</Typography>
          </Button>
        </Box>

        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <IconButton onClick={handleMenuClick}>
            <MenuIcon sx={{ color: "primary.main" }} />
          </IconButton>
          <Menu
            anchorEl={anchorElm}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              style: { padding: 0 },
            }}
            PaperProps={{
              style: {
                background: "linear-gradient(180deg, #000000 0%, #283266 100%)",
                border: "none",
                borderRadius: "20px",
                padding: "1em",
              },
            }}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                navigate("/");
              }}
              sx={{ color: "#FFF" }}
            >
              Home
            </MenuItem>
            <MenuItem sx={{ color: "#FFF" }}>
              <Accordion sx={{ bgcolor: "transparent", border: "none", p: 0 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#FFF" }} />}
                  sx={{ p: 0, color: "#FFF" }}
                >
                  Streamlines
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5em",
                    alignItems: "start",
                    p: 0,
                  }}
                >
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate("/streamlines/neo-digital");
                      setAnchorEl(null);
                    }}
                  >
                    Neo-Digital
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate("/streamlines/neo-pay");
                      setAnchorEl(null);
                    }}
                  >
                    Neo Pay
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/streamlines/business");
                      setAnchorEl(null);
                    }}
                  >
                    Neo Business
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate("/streamlines/neo-gaming");
                      setAnchorEl(null);
                    }}
                  >
                    Neo Gaming
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate("/streamlines/neo-learning");
                      setAnchorEl(null);
                    }}
                  >
                    Neo E-Learning
                  </Button>
                </AccordionDetails>
              </Accordion>
            </MenuItem>
            <MenuItem sx={{ color: "#FFF" }}>
              {" "}
              <Accordion sx={{ bgcolor: "transparent", border: "none", p: 0 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#FFF" }} />}
                  sx={{ p: 0, color: "#FFF" }}
                >
                  Resources
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5em",
                    alignItems: "start",
                    p: 0,
                  }}
                >
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate("/resources/about-us");
                      setAnchorEl(null);
                    }}
                  >
                    About Us
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate("/resources");
                      setAnchorEl(null);
                    }}
                  >
                    Careers
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/resources/partner");
                      setAnchorEl(null);
                    }}
                  >
                    Become a Partner{" "}
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/resources/culture");
                      setAnchorEl(null);
                    }}
                  >
                    Out Culture
                  </Button>
                </AccordionDetails>
              </Accordion>
            </MenuItem>

            <Divider />

            <MenuItem>
              <Button
                variant="contained"
                component={Link}
                to={"/contact"}
                sx={{
                  gap: "0.5em",
                  borderRadius: "10px",
                }}
              >
                <PhoneOutlined />
                <Typography>Contact Sales</Typography>
              </Button>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
